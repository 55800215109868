import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { DatePicker, Tag, Tooltip, Typography } from "antd";
import Button from "antd/es/button";
import Collapse from "antd/lib/collapse";
import Form from "antd/lib/form";
import Space from "antd/lib/space";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MultipleDateSelect from "../../../../components/CounselingForm/MultipleDateSelect";
import { FormInstance } from "antd/es/form";
import ModalAddTime from "./ModalAddTime";
import { StoreValue } from "antd/es/form/interface";
import VuiFormTitle from "../../../../@vodea/vodea-ui/components/Form/Title";
import { ValidateStatus } from "antd/es/form/FormItem";

const { Panel } = Collapse;

interface IDateForm {
  form: FormInstance;
  getFormError: (name: string) => string | undefined;
  getFormValidateStatus: (name: string) => ValidateStatus | undefined;
  clearFormErrorByName: (name: string) => void;
}

const DatesForm: React.FC<IDateForm> = ({
  form,
  getFormError,
  getFormValidateStatus,
  clearFormErrorByName,
}) => {
  const selectedMonth = Form.useWatch(`month`, form);
  const [onAddTime, setOnAddTime] = useState<
    ((defaultValue?: StoreValue, insertIndex?: number) => void) | undefined
  >(undefined);
  const { t } = useTranslation();

  return (
    <Space
      style={{
        width: "100%",
      }}
      direction="vertical"
      size={15}
    >
      <Form.Item
        name={`month`}
        label={"Month"}
        rules={[
          {
            required: true,
            message: t("validation.required", {
              item: "Month",
            }),
          },
        ]}
      >
        <DatePicker
          placeholder="Select Month"
          format="MMMM YYYY"
          onChange={() =>
            form.setFieldsValue({
              [`dates`]: [
                {
                  dates: [],
                  times: [],
                },
              ],
            })
          }
          style={{ width: "100%" }}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current < moment(customDate, "YYYY-MM-DD");
          }}
          size={"large"}
          picker="month"
        />
      </Form.Item>
      <Form.List name={`dates`}>
        {(fields, { add, remove }) => (
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={15}
          >
            {fields.map(({ key, name, ...restField }) => (
              <Collapse
                key={key}
                className="mb16"
                defaultActiveKey={[key]}
                expandIconPosition="end"
              >
                <Panel
                  header={`Date ${name + 1}`}
                  key={key}
                  extra={<DeleteOutlined onClick={() => remove(name)} />}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item shouldUpdate noStyle>
                        {() => {
                          const dates = form.getFieldValue([
                            `dates`,
                            name,
                            "dates",
                          ]);

                          return (
                            <>
                              <Form.Item
                                {...restField}
                                name={[name, "dates"]}
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Date",
                                    }),
                                  },
                                ]}
                                validateStatus={getFormValidateStatus(
                                  `dates.${name}.dates`
                                )}
                              >
                                <MultipleDateSelect
                                  selectedMonth={selectedMonth}
                                />
                              </Form.Item>
                              {dates?.map((date: any, i: number) => {
                                return (
                                  <Typography.Text>
                                    {getFormError(`dates.${name}.dates.${i}`)}
                                  </Typography.Text>
                                );
                              })}
                            </>
                          );
                        }}
                      </Form.Item>
                    </div>
                  </div>

                  <Form.List
                    name={[name, "times"]}
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(
                              new Error("Time is required")
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(
                      fieldTimes,
                      { add: addTimes, remove: removeTimes },
                      { errors }
                    ) => (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                      >
                        <VuiFormTitle title="Time" required />
                        <Space wrap>
                          {fieldTimes.map(
                            ({
                              key: keyTimes,
                              name: nameTimes,
                              ...restFieldTimes
                            }) => (
                              <Form.Item shouldUpdate key={keyTimes} noStyle>
                                {() => {
                                  const times = form.getFieldValue([
                                    `dates`,
                                    name,
                                    "times",
                                    nameTimes,
                                  ]);
                                  const startTime = moment(times[0]).format(
                                    "HH:mm"
                                  );
                                  const endTime = moment(times[1]).format(
                                    "HH:mm"
                                  );

                                  const error =
                                    getFormError(
                                      `online_dates.${name}.times.${nameTimes}.from`
                                    ) ??
                                    getFormError(
                                      `offline_dates.${name}.times.${nameTimes}.from`
                                    );

                                  return (
                                    <Tooltip title={error}>
                                      <Tag
                                        color={error ? "error" : "default"}
                                        closable
                                        onClose={() => {
                                          removeTimes(nameTimes);
                                          clearFormErrorByName(
                                            `online_dates.${name}.times.${nameTimes}.from`
                                          );
                                          clearFormErrorByName(
                                            `offline_dates.${name}.times.${nameTimes}.from`
                                          );
                                        }}
                                      >
                                        {startTime} - {endTime}
                                      </Tag>
                                    </Tooltip>
                                  );
                                }}
                              </Form.Item>
                            )
                          )}

                          <Tag
                            onClick={() =>
                              setOnAddTime(() => {
                                return addTimes;
                              })
                            }
                            className="site-tag-plus"
                          >
                            <PlusOutlined /> Add Time
                          </Tag>
                        </Space>

                        <Form.ErrorList errors={errors} />
                      </Space>
                    )}
                  </Form.List>
                </Panel>
              </Collapse>
            ))}

            <Button
              style={{ minWidth: 72 }}
              type="primary"
              onClick={() => add()}
              disabled={!selectedMonth}
              icon={<PlusOutlined />}
            >
              Add Date
            </Button>
          </Space>
        )}
      </Form.List>

      <ModalAddTime
        onSave={onAddTime}
        onClose={() => setOnAddTime(undefined)}
        visible={!!onAddTime}
      />
    </Space>
  );
};

export default DatesForm;
