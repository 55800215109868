import { useState } from "@hookstate/core";
import { Select } from "antd";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Accessible from "../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../@vodea/vodea-ui/components/Container";
import { ACCESS_PERMISSION } from "../../../constant";
import {
  handleBackendError,
  openNotification,
} from "../../../functions/global";
import PackageRepository from "../../../repositories/PackageRepository";

const { Option } = Select;

let title = "Package";

const AppPackage: React.FC<any> = () => {
  const { t } = useTranslation();
  const pageTitle = t("common.text.editItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
    };

    PackageRepository.update(formData)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        console.log(e);
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await PackageRepository.show()
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        if (!_.isEmpty(data)) {
          const modified: any = {
            ...data,
          };

          form.setFieldsValue({
            ...modified,
          });
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          backIcon={false}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div>
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={6}>
                          <div className="vui-form-group">
                            <div className="input-section">
                              <Select
                                value={5}
                                disabled={true}
                                size={"large"}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Option key={5} value={5}>
                                  5 Sessions
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </Col>

                        <Col flex="auto">
                          <div className="vui-form-group">
                            <div className="input-section">
                              <Form.Item
                                name="five_session_discount_percentage"
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Discount",
                                    }),
                                  },
                                ]}
                              >
                                <Input
                                  disabled={disable.get()}
                                  suffix="%"
                                  type="number"
                                  size={"large"}
                                  placeholder={t("common.text.input", {
                                    item: "Discount",
                                  })}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={6}>
                          <div className="vui-form-group">
                            <div className="input-section">
                              <Select
                                value={10}
                                disabled={true}
                                size={"large"}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Option key={10} value={10}>
                                  10 Sessions
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </Col>

                        <Col flex="auto">
                          <div className="vui-form-group">
                            <div className="input-section">
                              <Form.Item
                                name="ten_session_discount_percentage"
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Discount",
                                    }),
                                  },
                                ]}
                              >
                                <Input
                                  disabled={disable.get()}
                                  size={"large"}
                                  suffix="%"
                                  type="number"
                                  placeholder={t("common.text.input", {
                                    item: "Discount",
                                  })}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Accessible access={ACCESS_PERMISSION.package.update}>
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppPackage;
