import React from "react";
import { Editor, IAllProps } from "@tinymce/tinymce-react";

interface IVuiEditor extends IAllProps {
  onChange?: (val: any) => void;
}

export const vuiEditorBaseInit: any = {
  branding: false,
  height: 200,
  placeholder: "",
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | formatselect | code " +
    "bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help ",
};

const VuiEditor: React.FC<IVuiEditor> = ({ onChange, ...props }) => {
  return (
    <Editor
      {...props}
      cloudChannel="5-stable"
      onEditorChange={(content: any) => onChange?.(content)}
    />
  );
};

export default VuiEditor;
