import React, { useEffect } from "react";
import _ from "lodash";
import { Navigate, Outlet } from "react-router";
import VuiHeader from "../../@vodea/vodea-ui/components/Header";
import { useAppSelector } from "../../stores/hooks";
import { usePassportService } from "../../@vodea/utilities/hooks";
import VuiMainMenu from "../../@vodea/vodea-ui/components/MainMenu";
import { defaultMenus, psychologistMenus } from "../../constant/menu";
import VuiLoadingScreen from "../../@vodea/vodea-ui/components/LoadingScreen";
import AccountRepository from "../../repositories/AccountRepository"


const isPsychologistPath = "account.roles[0].is_psychologist";

const AppLayout: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const system = useAppSelector((state) => state.system);

  const [notif, setNotif] = React.useState<any>([])
  const [unreadNotif, setUnreadNotif] = React.useState<boolean>(false)

  useEffect(() => {  
    if(system.isLoggedIn){
      getNotifications()
    }
  }, [unreadNotif, system.isLoggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

  const getNotifications = async () => {
    try {
      let params: any = {
          per_page: 99999,
      } 
      if(unreadNotif){
          params['only'] = 'unread' 
      }
      const res = await AccountRepository.allNotification(params)
      setNotif(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleReadNotif = async (id: number | string) => {
    try{
      await AccountRepository.showNotification(id)
      getNotifications()
    } catch(err){

    }
  }

  if (isOnFetchingUser) {
    return <VuiLoadingScreen />;
  }

  if (!system.isLoggedIn) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <section id={"app-layout"}>
      <VuiHeader
        notif={notif} 
        handleUnreadSwitch={((value:boolean) => setUnreadNotif(value))} 
        handleReadNotif={handleReadNotif}
      />
      <div className="container-wrapper">
        <main className="navigation-vertical-wrapper show-lg">
          {system.isLoggedIn ? (
            <>
              <VuiMainMenu
                menus={
                  _.get(system, isPsychologistPath)
                    ? psychologistMenus
                    : defaultMenus
                }
                mode="inline"
              />
            </>
          ) : null}
        </main>

        <Outlet />
      </div>
    </section>
  );
};

export default AppLayout;
