import React from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import Input from "antd/es/input";
import AccountRepository from "../../../../repositories/AccountRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  openNotification,
} from "../../../../functions/global";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";

const breadcrumbs = [
  {
    label: "Account",
    link: "/account",
  },
  {
    label: "Change Password",
    link: "/account/change-password",
  },
];

const AppAccountChangePassword: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t("changePassword.title");

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      old_password: data.old_password,
      new_password: data.new_password,
    };

    await AccountRepository.updatePassword(payload)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
        form.resetFields();
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle breadcrumbs={breadcrumbs} title={title} />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row>
            <Col md={16} xs={24}>
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={25}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="old_password"
                        label={"Current Password"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Current Password",
                            }),
                          },
                          {
                            min: 8,
                            message: t("validation.type.min", {
                              item: "Current Password",
                              min: 8,
                            }),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={t("common.text.input", {
                            item: "Current Password",
                          })}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="new_password"
                        label={"New Password"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "New Password",
                            }),
                          },
                          {
                            min: 8,
                            message: t("validation.type.min", {
                              item: "New Password",
                              min: 8,
                            }),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={t("common.text.input", {
                            item: "New Password",
                          })}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="password_confirmation"
                        label={"New Password Confirmation"}
                        dependencies={["new_password"]}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "New Password Confirmation",
                            }),
                          },
                          {
                            min: 8,
                            message: t("validation.type.min", {
                              item: "New Password Confirmation",
                              min: 8,
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("new_password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t("validation.type.match", {
                                    item: "New Password Confirmation",
                                  })
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={t("common.text.input", {
                            item: "New Password Confirmation",
                          })}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Spin spinning={loading.get()}>
              <Button
                className="vui-btn"
                htmlType="submit"
                type="primary"
                size={"large"}
                disabled={disable.get()}
              >
                {t("common.button.save")}
              </Button>
            </Spin>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppAccountChangePassword;
