import "./style.less";
import { HashLoader } from "react-spinners";

interface Props {
  color?: string;
}

const VuiLoadingScreen: React.FC<Props> = ({ color = "#F9DF64" }) => {
  return (
    <div className="loading-screen-section">
      <HashLoader color={color} />
    </div>
  );
};

export default VuiLoadingScreen;
