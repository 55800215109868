import { Form, Radio, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IAnswerRadioScale, ICounselingFormBaseProps } from "../interface";

const CounselingFormRadioScale: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;
  const answer: IAnswerRadioScale = question.answers as IAnswerRadioScale;
  return (
    <Form.Item
      name={formName}
      label={question.question}
      rules={[
        {
          required: Boolean(question.is_required),
          message: t("validation.required", {
            item: "Answer",
          }),
        },
      ]}
      tooltip={question.tooltip}
    >
      <Radio.Group disabled={disabled}>
        <Space size={28}>
          <label>{answer.scale[0]}</label>
          {answer.option.map((answer: string, index: number) => (
            <Space key={index} direction="vertical" align="center">
              <label>{typeof answer === "string" ? answer : ""}</label>
              <Radio value={answer} key={index} />
            </Space>
          ))}
          <label>{answer.scale[1]}</label>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export default CounselingFormRadioScale;
