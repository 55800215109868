import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Switch from "antd/es/switch";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../../@vodea/vodea-ui/components/Editor";
import VuiSelectMulti from "../../../../../@vodea/vodea-ui/components/Select/Multi";
import VuiUploadImages from "../../../../../@vodea/vodea-ui/components/UploadImages";
import {
  ACCESS_PERMISSION,
  COST_INFORMATION_DURATION_OPTIONS,
  env,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../../functions/global";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import ExpertiseRepository from "../../../../../repositories/ExpertiseRepository";
import PsychologistRepository from "../../../../../repositories/PsychologistRepository";

const { TextArea } = Input;
const { Option } = Select;

const title = "Psychologist";

const AppPsychologistForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      expertise_ids: _.map(data.expertise_ids, "id"),
      category_ids: _.map(data.category_ids, "id"),
      photo_id: _.get(data, "photo_id[0].id"),
      is_active: Boolean(data.is_active),
    };

    await (!id
      ? PsychologistRepository.create(formData)
      : PsychologistRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const payload = {
      with: ["photo", "expertises", "categories"],
    };
    await PsychologistRepository.show(id, payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const modified: any = {
          ...data,
          is_active: Boolean(data.is_active),
          expertise_ids: data.expertises,
          category_ids: data.categories,
          photo_id: toFileList(data.photo),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Name"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Name",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Name",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="sku"
                        label={"SKU"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "SKU",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "SKU",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title"
                        label={"Title"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                            disabled={disable.get()}
                            apiKey={env.getTinyApiKey()}
                            init={{
                              ...vuiEditorBaseInit,
                              placeholder: t("common.text.input", {
                                item: "Title",
                              }),
                            }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description Service",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="summary"
                        label={"Summary"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Summary",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          disabled={disable.get()}
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Summary",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Image{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE.psychologist,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="photo_id"
                      >
                        <VuiUploadImages
                          isUploadFirst
                          disabled={disable.get()}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="expertise_ids"
                        label={"Expertise"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Expertise",
                            }),
                          },
                        ]}
                      >
                        <VuiSelectMulti
                          disabled={disable.get()}
                          repository={ExpertiseRepository}
                          placeholder={t("select.placeholder", {
                            item: "Expertise",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="category_ids"
                        label={"Category"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Category",
                            }),
                          },
                        ]}
                      >
                        <VuiSelectMulti
                          disabled={disable.get()}
                          repository={CategoryRepository}
                          placeholder={t("select.placeholder", {
                            item: "Category",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="mb16">
                    <Card
                      title={"Informasi Biaya"}
                      className="card-title-required"
                    >
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={6}>
                            <div className="vui-form-group">
                              <div className="input-section">
                                <Select
                                  value={
                                    COST_INFORMATION_DURATION_OPTIONS[0].value
                                  }
                                  disabled={true}
                                  size={"large"}
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder={t("common.text.input", {
                                    item: "Duration",
                                  })}
                                >
                                  {COST_INFORMATION_DURATION_OPTIONS.map(
                                    (item: any) => (
                                      <Option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          </Col>

                          <Col flex="auto">
                            <div className="vui-form-group">
                              <div className="input-section">
                                <Form.Item
                                  name="sixty_minutes_price"
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Price",
                                      }),
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={disable.get()}
                                    size={"large"}
                                    placeholder={t("common.text.input", {
                                      item: "Price",
                                    })}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                          <Col span={6}>
                            <div className="vui-form-group">
                              <div className="input-section">
                                <Select
                                  value={
                                    COST_INFORMATION_DURATION_OPTIONS[1].value
                                  }
                                  disabled={true}
                                  size={"large"}
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder={t("common.text.input", {
                                    item: "Duration",
                                  })}
                                >
                                  {COST_INFORMATION_DURATION_OPTIONS.map(
                                    (item: any) => (
                                      <Option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          </Col>

                          <Col flex="auto">
                            <div className="vui-form-group">
                              <div className="input-section">
                                <Form.Item
                                  name="ninety_minutes_price"
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Price",
                                      }),
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={disable.get()}
                                    size={"large"}
                                    placeholder={t("common.text.input", {
                                      item: "Price",
                                    })}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Space>
                    </Card>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="priority" label={"Priority"}>
                        <Input
                          type="number"
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Priority",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="is_active"
                        label={"Status"}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: t("validation.required", {
                        //       item: "Status",
                        //     }),
                        //   },
                        // ]}
                        valuePropName="checked"
                      >
                        <Switch disabled={disable.get()} />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.psychologist.update
                  : ACCESS_PERMISSION.psychologist.store
              }
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppPsychologistForm;
