import { useState } from "@hookstate/core";
import { Checkbox, Radio, Tabs } from "antd";
import Button from "antd/es/button";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiUpload from "../../../../@vodea/vodea-ui/components/Upload";
import CounselingForm from "../../../../components/CounselingForm";
import {
  counselingForm,
  getNoPhotoPlaceholder,
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import PsychologistScheduleRepository from "../../../../repositories/PsychologistScheduleRepository";
import FormItemView from "../../../../@vodea/vodea-ui/components/Form/FormItemView";
import moment from "moment/moment";
import { PsychologistScheduleHistoryData } from "./interface";
import { scheduleMethod } from "../../../../@vodea/utilities/string";
import { dateTimeViewFormat } from "../../../../@vodea/utilities/date";
import StatusTag from "../../../../components/StatusTag";
import { Media } from "../../../../entities/media";
import FormAction from "../../../../components/FormAction";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../@vodea/vodea-ui/components/Editor";
import { env } from "../../../../constant";

const { TabPane } = Tabs;
const { Title } = Typography;

const AppPsychologistScheduleHistoryView: React.FC<any> = () => {
  const componentToPrintRef = React.useRef(null);
  const internalFormToPrintRef = React.useRef(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = "View Schedule History";
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [data, setData] =
    React.useState<PsychologistScheduleHistoryData | null>(null);
  const [sessionType, setSessionType] = React.useState<string>("COUNSELING");

  // Form
  const [form] = Form.useForm();
  const [internalForm, setInternalForm] = React.useState<any>(null);
  const disable = useState(false);
  const isSavingNotes = useState(false);

  // patient form
  const [patientFormInstance] = Form.useForm();
  const [patientForm, setPatientForm] = React.useState<any>(null);

  // internal form
  const [internalFormInstance] = Form.useForm();

  const onFinish = async (data: any) => {
    isSavingNotes.set(true);

    const formData: any = {
      notes: data.notes,
      file_id: _.get(data, "file[0].id"),
      booking_forms: [
        {
          form_id: internalForm?.id,
          booking_forms_answers: counselingForm.toSave(data),
        },
      ],
    };

    const reports = _.get(data, "reports");
    if (reports !== undefined) {
      Object.assign(formData, {
        report_ids: reports.map((item: Media) => item.id),
      });
    }

    await PsychologistScheduleRepository.update(id, formData)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: "Notes" }),
        );
        isSavingNotes.set(false);
        getData();
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        isSavingNotes.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData().then();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsFetchingData(true);

    const payload = {
      with: [
        "booking.customer.photo",
        "booking.bookingForms.bookingFormAnswers.formQuestion",
        "booking.bookingForms.form",
        "booking.category",
        "booking.file",
        "status",
        "psychologist",
        "booking.reports.file",
      ],
    };
    await PsychologistScheduleRepository.show(id, payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        setData(data);
        setSessionType(data.session_type);

        let questionAnswerInternal: any = {};

        let formTypeInternal = "Internal";
        if (data.session_type === "ASSESSMENT") {
          formTypeInternal = "Internal Assessment";
        }

        const internalFormData = data?.booking?.booking_forms?.find(
          (item: any) => item.type === formTypeInternal,
        );
        const patientFormData = data?.booking?.booking_forms?.find(
          (item: any) => item.type !== formTypeInternal,
        );

        if (patientFormData) {
          const counselingFormData = patientFormData.booking_form_answers?.map(
            (item: any) => {
              return item?.old_question || item?.form_question;
            },
          );
          setPatientForm({
            id: patientFormData.form_id,
            form_questions: counselingFormData,
            name: patientFormData?.form.name,
            description: patientFormData?.form.description,
          });

          const patientQuestionAnswer = counselingForm.toShow(
            patientFormData.booking_form_answers,
          );

          patientFormInstance.setFieldsValue(patientQuestionAnswer);
        }

        if (internalFormData) {
          const counselingFormData = internalFormData.booking_form_answers?.map(
            (item: any) => {
              return item?.old_question || item?.form_question;
            },
          );
          setInternalForm({
            id: internalFormData.form_id,
            form_questions: counselingFormData,
            name: internalFormData?.form.name,
            description: internalFormData?.form.description,
            can_download: true,
          });
          questionAnswerInternal = counselingForm.toShow(
            internalFormData.booking_form_answers,
          );
          internalFormInstance.setFieldsValue(questionAnswerInternal);
        } else {
          getFormData(data.session_type);
        }

        form.setFieldsValue({
          ...questionAnswerInternal,
          notes: data?.booking?.notes,
          file: toFileList(data?.booking?.file),
          reports: toFileList(
            data?.booking?.reports.map((report: any) => report.file),
          ),
        });
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        disable.set(true);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const getFormData = useCallback(
    async (sessionType) => {
      if (!sessionType) return;

      if (sessionType === "COUNSELING") {
        await PsychologistScheduleRepository.form()
          .then((res: AxiosResponse) => {
            const data = res.data?.data || {};
            setInternalForm(data);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      }

      if (sessionType === "ASSESSMENT") {
        await PsychologistScheduleRepository.formAssessment()
          .then((res: AxiosResponse) => {
            const data = res.data?.data || {};
            setInternalForm(data);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      }
    },
    [t],
  );

  //handle counseling form print
  const reactToPrintContent = React.useCallback(() => {
    return componentToPrintRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentToPrintRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Counseling Form",
    removeAfterPrint: true,
  });
  //end handle counseling from print

  //handle internal form print
  const internalFormPrintContent = React.useCallback(() => {
    return internalFormToPrintRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalFormToPrintRef.current]);

  const handleInternalFormPrint = useReactToPrint({
    content: internalFormPrintContent,
    documentTitle: "Internal Form",
    removeAfterPrint: true,
  });
  //end handle internal form print

  const headerExtra = useMemo(() => {
    const extra = [];

    if (patientForm) {
      extra.push(
        <Button onClick={handlePrint} key="1" type="primary">
          Download Counseling Form
        </Button>,
      );
    }
    if (internalForm?.can_download) {
      extra.push(
        <Button onClick={handleInternalFormPrint} key="2" type="primary">
          Download Internal Form
        </Button>,
      );
    }

    return extra;
  }, [handleInternalFormPrint, handlePrint, internalForm, patientForm]);

  const renderExternal = () => {
    return (
      <div className="vui-form-group type-column">
        <div className="input-section">
          <Form.Item
            name="notes"
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: "Notes",
                }),
              },
            ]}
          >
            <VuiEditor
              disabled={disable.get()}
              apiKey={env.getTinyApiKey()}
              init={{
                ...vuiEditorBaseInit,
                placeholder: t("common.text.input", {
                  item: "Notes",
                }),
              }}
            />
          </Form.Item>
        </div>

        {sessionType === "ASSESSMENT" && (
          <Form.Item label="Report" name="reports">
            <VuiUpload isUploadFirst max={10} />
          </Form.Item>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <VuiContainer bottomSpace={120}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          extra={headerExtra}
          tags={
            data?.status && (
              <StatusTag title={_.get(data, "status.label", "")} />
            )
          }
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={() =>
            openNotification("error", t("notification.error.uncompleteForm"))
          }
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} lg={8}>
              <Space className="flex" direction="vertical" size={20}>
                <Card loading={isFetchingData}>
                  <Space direction="vertical" size={16}>
                    <FormItemView
                      label="Psychologist"
                      value={_.get(data, "psychologist.name")}
                    />
                    <FormItemView
                      label="Type"
                      value={_.startCase(_.lowerCase(sessionType))}
                    />
                    {sessionType === "COUNSELING" ? (
                      <FormItemView
                        label="Category"
                        value={data?.booking?.category?.name}
                      />
                    ) : (
                      <FormItemView
                        label="Assessment Name"
                        value={data?.booking?.assessment_name}
                      />
                    )}
                    {Boolean(data?.booking?.is_package) && (
                      <FormItemView
                        label="Session Number"
                        value={`${data?.booking?.sequence} of ${
                          data?.booking?.sessions?.length || 1
                        }`}
                      />
                    )}
                    <FormItemView
                      label="Method"
                      value={scheduleMethod(_.get(data, "type", ""))}
                    />
                    <FormItemView
                      label="Date | Time"
                      value={
                        data?.date ? (
                          <>
                            {dateTimeViewFormat(
                              data?.date,
                              data?.time_from,
                              data?.time_to,
                            )}
                          </>
                        ) : (
                          "-"
                        )
                      }
                    />
                    {sessionType === "COUNSELING" && (
                      <FormItemView
                        label="Counseling Form"
                        value={
                          counselingForm && patientForm ? (
                            <Button
                              type="primary"
                              size={"large"}
                              onClick={handlePrint}
                            >
                              Download Counseling Form
                            </Button>
                          ) : (
                            "Not Filled"
                          )
                        }
                      />
                    )}
                    {!!data?.booking?.gmeet_link && (
                      <FormItemView
                        label="Google Meet Link"
                        value={
                          data?.booking?.gmeet_link ? (
                            <a
                              href={data?.booking?.gmeet_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <u>{data?.booking?.gmeet_link}</u>
                            </a>
                          ) : (
                            "-"
                          )
                        }
                      />
                    )}
                  </Space>
                </Card>
              </Space>
            </Col>

            <Col xs={24} lg={16}>
              <Space className="flex" direction="vertical" size={20}>
                <Card
                  title={
                    <Typography.Title level={5}>
                      Patient Information
                    </Typography.Title>
                  }
                  loading={isFetchingData}
                >
                  <Space className="flex" direction="vertical" size={20}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} lg={8}>
                        <FormItemView
                          label="Patient Photo"
                          value={
                            data?.booking?.customer?.photo ??
                            getNoPhotoPlaceholder()
                          }
                          type="file"
                        />
                      </Col>
                      <Col xs={24} lg={16}>
                        <Row gutter={[16, 30]}>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Patient Name"
                              value={data?.booking?.customer?.name}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Email"
                              value={_.get(data, "booking.customer.email")}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Phone Number"
                              value={_.get(
                                data,
                                "booking.customer.phone_number",
                              )}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Date of Birth"
                              value={moment(
                                _.get(data, "booking.customer.date_of_birth"),
                              ).format("DD MMM YYYY")}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} lg={8}>
                        <FormItemView
                          label="Gender"
                          value={_.get(data, "booking.customer.gender")}
                        />
                      </Col>
                      <Col xs={24} lg={16}>
                        <FormItemView
                          label="Address"
                          value={_.get(data, "booking.customer.address")}
                        />
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </Space>
            </Col>

            <Col xs={24} lg={24}>
              <Space className="flex" direction="vertical" size={20}>
                <Card
                  loading={isFetchingData}
                  className="schedule-patient-section"
                >
                  <Tabs defaultActiveKey="online">
                    <TabPane forceRender tab="External" key="external">
                      {renderExternal()}
                    </TabPane>
                    <TabPane forceRender tab="Internal" key="internal">
                      <CounselingForm
                        formData={internalForm?.form_questions || []}
                        disabled={disable.get()}
                      />
                      {sessionType === "COUNSELING" && (
                        <Form.Item label="File" name="file">
                          <VuiUpload disabled={disable.get()} isUploadFirst />
                        </Form.Item>
                      )}
                    </TabPane>
                  </Tabs>
                </Card>
              </Space>
            </Col>
          </Row>
          {!isFetchingData && (
            <FormAction
              formName={""}
              onCancel={() => navigate(-1)}
              onSubmitting={isSavingNotes.get()}
              submitAccess={"IGNORE"}
              submitTitle="Save"
              showSubmit={!!internalForm}
            />
          )}
        </Form>

        {Boolean(patientForm) && (
          <div style={{ display: "none" }}>
            <div ref={componentToPrintRef} id="counseling-form">
              <style type="text/css" media="print">
                {
                  "\
                  @page { size: potrait;}\
                "
                }
              </style>
              <div className="counseling-form-container">
                <img
                  className="logo mb24"
                  src={"/images/logo.png"}
                  alt="logo"
                />
                <Title className="page-title mb24" level={3}>
                  {patientForm.name}
                </Title>
                <div
                  className="page-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: patientForm.description,
                  }}
                />
                <div className="mb24" />
                <Form form={patientFormInstance} layout={"vertical"}>
                  <CounselingForm
                    formData={patientForm.form_questions}
                    disabled={disable.get()}
                    forPrint
                  />
                </Form>
              </div>
            </div>
          </div>
        )}
        {internalForm?.can_download && (
          <div style={{ display: "none" }}>
            <div ref={internalFormToPrintRef} id="counseling-form">
              <style type="text/css" media="print">
                {
                  "\
                    @page { size: potrait;}\
                  "
                }
              </style>
              <div className="counseling-form-container">
                <img
                  className="logo mb24"
                  src={"/images/logo.png"}
                  alt="logo"
                />
                <Title className="page-title mb24" level={3}>
                  {internalForm.name}
                </Title>
                <div
                  className="page-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: internalForm.description,
                  }}
                />
                <div className="mb24" />
                <Form form={internalFormInstance} layout={"vertical"}>
                  <CounselingForm
                    formData={internalForm.form_questions}
                    disabled={disable.get()}
                    forPrint
                  />
                </Form>
              </div>
            </div>
          </div>
        )}
      </VuiContainer>
    </>
  );
};

export default AppPsychologistScheduleHistoryView;
