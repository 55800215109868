import "./style.less";

import { useCallback } from "react";
import { Button, Modal } from "antd";
import { ButtonType } from "antd/es/button";
import PageFooter, { PageFooterProps } from "../PageFooter";
import { useTranslation } from "react-i18next";
import Accessible from "../../@vodea/vodea-ui/components/Accessible";

interface FormActionProps {
  title?: PageFooterProps["title"];
  suffix?: PageFooterProps["suffix"];
  lastUpdate?: string;
  updatedBy?: string;
  formName: string;
  deleteAccess?: string;
  onDelete?: () => void;
  submitAccess?: string;
  onSubmitting?: boolean;
  submitTitle?: string;
  showSubmit?: boolean;
  onCancel: () => void;
  cancelTitle?: string;
  cancelBtnType?: ButtonType;
  isEdit?: boolean;
}

const { confirm } = Modal;

const FormAction = (props: FormActionProps) => {
  const { t } = useTranslation("translation");
  const {
    title,
    formName,
    suffix,
    deleteAccess = "",
    onDelete,
    submitAccess = "",
    onSubmitting,
    submitTitle,
    onCancel,
    cancelTitle,
    cancelBtnType = "default",
    showSubmit = true,
    isEdit,
  } = props;

  const openDeleteConfirm = useCallback(() => {
    confirm({
      title: t("modal.deleteConfirmation.title", {
        item: formName,
      }),
      content: t("modal.deleteConfirmation.subtitle", {
        item: formName,
      }),
      okType: "danger",
      okText: t("common.button.delete"),
      centered: true,
      onOk: onDelete,
      closable: false,
    });
  }, [formName, onDelete, t]);

  const getTitle = () => {
    return title ?? "";
  };

  const getCancelTitle = () => {
    if (cancelTitle) {
      return cancelTitle;
    }

    return t("common.button.back");
  };

  const getSubmitTitle = () => {
    if (submitTitle) {
      return submitTitle;
    }

    return t(`common.button.${isEdit ? "save" : "submit"}`);
  };

  const renderDelete = () => {
    if (onDelete) {
      return (
        <Accessible access={deleteAccess}>
          <Button type="link" block={false} danger onClick={openDeleteConfirm}>
            {t("common.button.delete")}
          </Button>
        </Accessible>
      );
    }
  };

  const renderSubmit = () => {
    if (showSubmit) {
      return (
        <Accessible access={submitAccess}>
          <Button
            htmlType="submit"
            type="primary"
            block={false}
            loading={onSubmitting}
          >
            {getSubmitTitle()}
          </Button>
        </Accessible>
      );
    }
  };

  return (
    <PageFooter
      className="form-action"
      title={getTitle()}
      suffix={
        <>
          {renderDelete()}
          <Button type={cancelBtnType} block={false} onClick={onCancel}>
            {getCancelTitle()}
          </Button>
          {renderSubmit()}
          {suffix}
        </>
      }
    />
  );
};

export default FormAction;
