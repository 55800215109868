import "./style.less";

import {useState} from "@hookstate/core";
import {Checkbox, Tabs} from "antd";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import {Col, Row} from "antd/lib/grid";
import Space from "antd/lib/space";
import {AxiosError, AxiosResponse} from "axios";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import {ACCESS_PERMISSION} from "../../../../constant";
import {openNotification} from "../../../../functions/global";
import ScheduleRepository from "../../../../repositories/ScheduleRepository";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import DatesForm from "./DatesForm";
import {scheduleSessionTypes, scheduleTypes,} from "../../../../constant/schedule";
import _ from "lodash";
import {ScheduleType} from "../../../../entities/schedule";
import {ScheduleCreateFormData} from "./interface";
import {ScheduleCreatePayload} from "../../../../entities/dtos/schedule";
import FormAction from "../../../../components/FormAction";
import {useFormErrors} from "../../../../@vodea/utilities/hooks";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";

const { TabPane } = Tabs;

let title = "Schedule";

const AppScheduleFormCreate: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const {
    getFormError,
    getFormValidateStatus,
    setFormError,
    clearFormError,
    clearFormErrorByName,
  } = useFormErrors();
  const selectedMethods = (Form.useWatch("methods", form) ??
    []) as ScheduleType[];
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: ScheduleCreateFormData) => {
    const { psychologist_id, session_type, dates } = data ?? {};

    const formData: ScheduleCreatePayload = {
      psychologist_ids: psychologist_id.map((item) => item.id),
      is_assessment: session_type.includes("ASSESSMENT"),
      is_counseling: session_type.includes("COUNSELING"),
    };


    if (selectedMethods.includes("Online")) {
      formData.online_dates = dates?.map((item) => {
        return {
          dates: item.dates.map((date) => date.value) as string[],
          times: item.times.map((time) => {
            return {
              from: moment(_.get(time, [0])).format("HH:mm"),
              to: moment(_.get(time, [1])).format("HH:mm"),
            };
          }),
        };
      });
    }

    if (selectedMethods.includes("Offline")) {
      formData.offline_dates = dates?.map((item) => {
        return {
          dates: item.dates.map((date) => date.value) as string[],
          times: item.times.map((time) => {
            return {
              from: moment(_.get(time, [0])).format("HH:mm"),
              to: moment(_.get(time, [1])).format("HH:mm"),
            };
          }),
        };
      });
    }

    await ScheduleRepository.create(formData)
      .then((res: AxiosResponse) => {
        navigate(-1);
        openNotification(
          "success",
          t("notification.success.createItem", { item: title })
        );
      })
      .catch((e: AxiosError) => {
        loading.set(false);
        setFormError(e);
        openNotification("error", _.get(e, "response.data.message"));
      });
  };

  const renderLeftSection = () => {
    return (
      <Col xs={24} lg={8}>
        <Card>
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={0}
          >
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="psychologist_id"
                  label={"Psychologist"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Psychologist",
                      }),
                    },
                  ]}
                >
                  <VuiSelectMulti
                    repository={PsychologistRepository}
                    disabled={disable.get()}
                    placeholder={t("select.placeholder", {
                      item: "Psychologist",
                    })}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="session_type"
                  label={"Type"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Type",
                      }),
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      {scheduleSessionTypes.map((item) => {
                        return (
                          <Checkbox key={item} value={item}>
                            {_.capitalize(item)}
                          </Checkbox>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="methods"
                  label={"Method"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Method",
                      }),
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      {scheduleTypes.map((item) => {
                        return (
                          <Checkbox key={item} value={item}>
                            {_.capitalize(item)}
                          </Checkbox>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
          </Space>
        </Card>
      </Col>
    );
  };

  const renderRightSection = () => {
    return (
      <Col xs={24} lg={16}>
        <Card>
          <Tabs defaultActiveKey="online">
            <TabPane forceRender tab={selectedMethods.join(" & ")}>
              <DatesForm
                form={form}
                getFormError={getFormError}
                getFormValidateStatus={getFormValidateStatus}
                clearFormErrorByName={clearFormErrorByName}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer bottomSpace={110}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={(err) => {
            openNotification("warning", t("notification.error.uncompleteForm"));
          }}
          onValuesChange={clearFormError}
        >
          <Row className="schedule-form-create-container" gutter={[20, 20]}>
            {renderLeftSection()}
            {selectedMethods.length > 0 && renderRightSection()}
          </Row>
          <FormAction
            formName={title}
            onCancel={() => navigate(-1)}
            onSubmitting={loading.get()}
            submitAccess={ACCESS_PERMISSION.schedule.store}
          />
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppScheduleFormCreate;
