import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiUploadImages from "../../../../@vodea/vodea-ui/components/UploadImages";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import SocialRepository from "../../../../repositories/SocialRepository";
import _ from "lodash";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";
import {
  ACCESS_PERMISSION,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../constant";

const title = "Social";

const AppSocialForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      photo_id: _.get(data, "photo_id[0].id"),
    };

    SocialRepository.update(id, formData)
      .then((res: AxiosResponse) => {
        navigate(-1);

        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await SocialRepository.show(id, { with: ["photo"] })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const modified: any = {
          photo_id: toFileList(data.photo),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>Edit {title}</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Image
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE.social,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="photo_id"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>

            <Spin spinning={loading.get()}>
              <Button
                className="vui-btn"
                type="primary"
                htmlType="submit"
                size={"large"}
              >
                Submit
              </Button>
            </Spin>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppSocialForm;
