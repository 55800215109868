import { LoadingOutlined } from "@ant-design/icons";
import Upload from "antd/lib/upload";
import React, { useEffect } from "react";
import { toFileList } from "../../../../functions/global";
import useMediaService from "../../../utilities/hooks/useMediaService";

interface Props {
  value?: any[];
  disabled?: boolean;
  onChange?: (value: any[]) => void;
  max?: number;
  isUploadFirst?: boolean;
}

const VuiUploadImages: React.FC<Props> = ({
  value = [],
  onChange,
  disabled,
  max = 1,
  isUploadFirst,
}) => {
  const { storeMedia } = useMediaService();
  const [fileList, setFileList] = React.useState<any>(value);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setFileList(value);
  }, [value.length]);

  const handleOnChange: any = ({ fileList: newFileList }: any) => {
    let originalsFileObjects = newFileList.map((item: any) => {
      return item?.originFileObj;
    });

    if (isUploadFirst && originalsFileObjects?.length > 0) {
      setIsLoading(true);
      const formData = {
        files: originalsFileObjects,
        path: "file",
        disk: "public",
      };

      storeMedia(formData)
        .then((response: any) => {
          const uploadedFiles = toFileList(response);
          setFileList(uploadedFiles);
          onChange?.(uploadedFiles);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setFileList(newFileList);
      onChange?.(originalsFileObjects);
    }
  };

  const handleOnPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) imgWindow.document.write(image.outerHTML);
  };

  return (
    <>
      <Upload
        disabled={disabled}
        accept={"image/*"}
        listType="picture-card"
        fileList={fileList}
        onChange={handleOnChange}
        onPreview={handleOnPreview}
        beforeUpload={(test) => {
          return false;
        }}
      >
        {isLoading ? (
          <LoadingOutlined />
        ) : (
          fileList.length < max && !disabled && "+ Upload"
        )}
        {/* {fileList.length < max && !disabled && "+ Upload"} */}
      </Upload>
    </>
  );
};

export default VuiUploadImages;
