import { Helmet } from "react-helmet-async";
import PageHeader from "antd/es/page-header";
import React, { useCallback, useMemo, useState } from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import Input from "antd/es/input";
import VuiUploadImages from "../../../../@vodea/vodea-ui/components/UploadImages";
import { ACCESS_PERMISSION, env } from "../../../../constant";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../@vodea/vodea-ui/components/Editor";
import Switch from "antd/es/switch";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import FormAction from "../../../../components/FormAction";
import { Psychologist } from "../../../../entities/pshychologist";
import { Media } from "../../../../entities/media";
import AssessmentRepository from "../../../../repositories/AssessmentRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";

const { Text } = Typography;

type FormData = {
  name: string;
  price: number;
  photo_id: Media[];
  description: string;
  instruction: string;
  is_active: boolean;
  has_schedule: boolean;
  is_online: boolean;
  is_offline: boolean;
  psychologist_ids: Psychologist[];
  sessions: {
    session_duration: number;
    session_min_days: number;
  }[];
};

export const AppAssessmentForm = () => {
  const { id } = useParams();
  const pageTitle = `${id ? "Edit" : "Add"} Assessment & Programs`;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFetchingDataLoading, setFetchingDataLoading] =
    useState<boolean>(false);
  const [tabBarActiveKey, setTabBarActiveKey] = useState<string>("description");

  const hasSchedule = Form.useWatch("has_schedule", form);

  const [mustSelectMethod, setMustSelectMethod] = useState<boolean>(false);
  const [mustHaveSessions, setMustHaveSessions] = useState<boolean>(false);

  const onFinish = useCallback(
    async (data: FormData) => {
      let hasError = false;

      if (data.has_schedule && !data.is_online && !data.is_offline) {
        setMustSelectMethod(true);
        hasError = true;
      }

      if (data.has_schedule && data.sessions.length === 0) {
        setMustHaveSessions(true);
        hasError = true;
      }

      if (hasError) return;

      setIsSubmitLoading(true);

      const payload = {
        name: data.name,
        price: data.price,
        description: data?.description,
        instruction: data?.instruction,
        is_active: data.is_active,
        has_schedule: data.has_schedule,
      };

      if (data.photo_id && data.photo_id.length) {
        Object.assign(payload, {
          photo_id: data.photo_id[0].id,
        });
      }

      if (data.has_schedule) {
        Object.assign(payload, {
          is_online: data.is_online,
          is_offline: data.is_offline,
          sessions: data.sessions || [],
          psychologist_ids: data.psychologist_ids.map((item) => item.id) || [],
        });
      }

      await (id
        ? AssessmentRepository.update(id, payload)
        : AssessmentRepository.create(payload)
      )
        .then(() => {
          navigate("/assessment");

          if (!id) {
            openNotification(
              "success",
              t("notification.success.createItem", { item: "Assessment" })
            );
          } else {
            openNotification(
              "success",
              t("notification.success.updateItem", { item: "Assessment" })
            );
          }
        })
        .catch((err: AxiosError) => {
          handleBackendError(err, t("notification.error.default"));
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    },
    [id]
  );

  const loadData = useCallback(async () => {
    if (!id) return;

    setFetchingDataLoading(true);

    await AssessmentRepository.show(id, {
      with: ["photo", "schedules", "psychologists", "sessions"],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;

        form.setFieldsValue({
          name: responseData.name,
          price: responseData.price,
          description: responseData.description,
          instruction: responseData.instruction,
          is_active: responseData.is_active,
          has_schedule: responseData.has_schedule,
          is_online: responseData.is_online,
          is_offline: responseData.is_offline,
          photo_id: toFileList(responseData.photo),
          sessions: responseData.sessions.map((session: any) => ({
            session_min_days: session.session_min_days,
            session_duration: session.session_duration,
          })),
          psychologist_ids: responseData.psychologists || [],
        });
      })
      .catch((err: AxiosError) => {
        handleBackendError(err, t("notification.error.default"));
      })
      .finally(() => {
        setFetchingDataLoading(false);
      });
  }, [id]);

  useMemo(() => {
    (async () => {
      loadData().then();
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <VuiContainer bottomSpace={110}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            has_schedule: false,
            is_active: false,
            is_online: false,
            is_offline: false,
            description: "",
            instruction: "",
          }}
        >
          <Row gutter={[20, 20]}>
            <Col className="gutter-row" lg={24} xs={24}>
              <Card className="mb16" loading={isFetchingDataLoading}>
                <Space direction="vertical" size={20} style={{ width: "100%" }}>
                  <Row gutter={[20, 20]}>
                    <Col lg={12} xs={24}>
                      <Row gutter={[20, 20]}>
                        <Col lg={6} xs={24}>
                          <Form.Item
                            name="photo_id"
                            label={"Image"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Image",
                                }),
                              },
                            ]}
                          >
                            <VuiUploadImages isUploadFirst />
                          </Form.Item>
                        </Col>
                        <Col lg={18} xs={24}>
                          <Form.Item
                            name="name"
                            label={"Name"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Name",
                                }),
                              },
                            ]}
                          >
                            <Input
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Name",
                              })}
                            />
                          </Form.Item>

                          <Form.Item
                            name="price"
                            label={"Price"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Price",
                                }),
                              },
                              {
                                min: 1,
                                type: "number",
                                message: t("validation.type.number.min", {
                                  item: "Price",
                                  min: 1,
                                }),
                              },
                            ]}
                          >
                            <InputNumber
                              size={"large"}
                              type="number"
                              style={{ width: "100%" }}
                              placeholder={t("common.text.input", {
                                item: "Price",
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="is_active"
                            label={"Status"}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Tabs
                        defaultActiveKey="description"
                        onChange={(key) => setTabBarActiveKey(key)}
                      >
                        <Tabs.TabPane
                          forceRender
                          tab={
                            <Text
                              style={{
                                color: (form.getFieldError("description") || [])
                                  .length
                                  ? "red"
                                  : tabBarActiveKey === "description"
                                  ? "#F9DF64"
                                  : "#000",
                              }}
                            >
                              Description
                            </Text>
                          }
                          key="description"
                        >
                          <Form.Item
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Description",
                                }),
                              },
                            ]}
                          >
                            <VuiEditor
                              apiKey={env.getTinyApiKey()}
                              init={{
                                ...vuiEditorBaseInit,
                                placeholder: t("common.text.input", {
                                  item: "Description",
                                }),
                              }}
                            />
                          </Form.Item>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          forceRender
                          tab={
                            <Text
                              style={{
                                color: (form.getFieldError("instruction") || [])
                                  .length
                                  ? "red"
                                  : tabBarActiveKey === "instruction"
                                  ? "#F9DF64"
                                  : "#000",
                              }}
                            >
                              Instruction
                            </Text>
                          }
                          key="instruction"
                        >
                          <Form.Item
                            name="instruction"
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Instruction",
                                }),
                              },
                            ]}
                          >
                            <VuiEditor
                              apiKey={env.getTinyApiKey()}
                              init={{
                                ...vuiEditorBaseInit,
                                placeholder: t("common.text.input", {
                                  item: "Instruction",
                                }),
                              }}
                            />
                          </Form.Item>
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Space>
              </Card>

              <Card loading={isFetchingDataLoading}>
                <Space direction="vertical" size={20} style={{ width: "100%" }}>
                  <Form.Item
                    label="Schedule"
                    name="has_schedule"
                    valuePropName="checked"
                    className="mb0"
                  >
                    <Checkbox>{t("assessment.hasSchedule")}</Checkbox>
                  </Form.Item>

                  {hasSchedule && (
                    <Card>
                      <Space
                        direction="vertical"
                        size={20}
                        style={{ width: "100%" }}
                      >
                        <Row gutter={[20, 20]}>
                          <Col lg={12} xs={24}>
                            <Form.Item
                              name="psychologist_ids"
                              label={"Psychologist"}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: "Psychologist",
                                  }),
                                },
                              ]}
                              className="mb0"
                            >
                              <VuiSelectMulti
                                repository={PsychologistRepository}
                                placeholder={t("select.placeholder", {
                                  item: "Psychologist",
                                })}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <div className="ant-col ant-form-item-label">
                              <label className="ant-form-item-required">
                                Method
                              </label>
                            </div>

                            <Space size={20} style={{ width: "100%" }}>
                              <Form.Item
                                className="mb0"
                                name="is_online"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  onChange={(value) => {
                                    setMustSelectMethod(false);
                                    return value;
                                  }}
                                >
                                  Online
                                </Checkbox>
                              </Form.Item>
                              <Form.Item
                                name="is_offline"
                                valuePropName="checked"
                                className="mb0"
                              >
                                <Checkbox
                                  onChange={(value) => {
                                    setMustSelectMethod(false);
                                    return value;
                                  }}
                                >
                                  Face to Face
                                </Checkbox>
                              </Form.Item>
                            </Space>
                            {mustSelectMethod && (
                              <div className="ant-form-item-explain">
                                <div
                                  role="alert"
                                  className="ant-form-item-explain-error"
                                >
                                  Method is required
                                </div>
                              </div>
                            )}
                          </Col>

                          <Col lg={24} xs={24}>
                            <Divider className="mb0 mt0" />
                          </Col>

                          <Col lg={24} xs={24}>
                            <Form.List name="sessions">
                              {(fields, { add, remove }) => (
                                <Card
                                  title={"Session"}
                                  extra={
                                    <Button
                                      type="primary"
                                      onClick={() => {
                                        add({
                                          session_duration: 0,
                                          session_min_days: 0,
                                        });
                                        setMustHaveSessions(false);
                                      }}
                                      icon={<PlusOutlined />}
                                    >
                                      Add Session
                                    </Button>
                                  }
                                  bodyStyle={{
                                    padding: fields.length ? 24 : 0,
                                  }}
                                >
                                  {fields.length ? (
                                    <Row
                                      gutter={[20, 20]}
                                      className="mb18"
                                      align="middle"
                                    >
                                      <Col xs={4}>
                                        <Text>Session</Text>
                                      </Col>
                                      <Col xs={9}>
                                        <div className="ant-col ant-form-item-label">
                                          <label className="ant-form-item-required">
                                            Duration
                                          </label>
                                        </div>
                                      </Col>
                                      <Col xs={9}>
                                        <div className="ant-col ant-form-item-label">
                                          <label className="ant-form-item-required">
                                            Mininum Days for Next Session
                                          </label>
                                        </div>
                                      </Col>
                                      <Col xs={2} />

                                      {fields.map(
                                        ({ key, name, ...restField }) => (
                                          <>
                                            <Col xs={4}>
                                              <Text>Session {name + 1}</Text>
                                            </Col>
                                            <Col xs={9}>
                                              <Form.Item
                                                {...restField}
                                                name={[
                                                  name,
                                                  "session_duration",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t(
                                                      "validation.required",
                                                      {
                                                        item: "Duration",
                                                      }
                                                    ),
                                                  },
                                                ]}
                                                className="mb0"
                                              >
                                                <InputNumber
                                                  size={"large"}
                                                  style={{ width: "100%" }}
                                                  addonAfter="Minutes"
                                                  type="number"
                                                  min={0}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col xs={9}>
                                              {name + 1 !== fields.length && (
                                                <Form.Item
                                                  {...restField}
                                                  name={[
                                                    name,
                                                    "session_min_days",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: t(
                                                        "validation.required",
                                                        {
                                                          item: "Days",
                                                        }
                                                      ),
                                                    },
                                                  ]}
                                                  className="mb0"
                                                >
                                                  <InputNumber
                                                    size={"large"}
                                                    style={{ width: "100%" }}
                                                    addonAfter="Days"
                                                    type="number"
                                                    min={0}
                                                  />
                                                </Form.Item>
                                              )}
                                            </Col>
                                            <Col
                                              xs={2}
                                              style={{ textAlign: "right" }}
                                            >
                                              <DeleteOutlined
                                                onClick={() => remove(name)}
                                                style={{ fontSize: 16 }}
                                              />
                                            </Col>
                                          </>
                                        )
                                      )}
                                    </Row>
                                  ) : null}
                                  {mustHaveSessions && (
                                    <div
                                      className="ant-form-item-explain"
                                      style={{ padding: 24, paddingTop: 0 }}
                                    >
                                      <div
                                        role="alert"
                                        className="ant-form-item-explain-error"
                                      >
                                        Sessions must be at least 1
                                      </div>
                                    </div>
                                  )}
                                </Card>
                              )}
                            </Form.List>
                          </Col>
                        </Row>
                      </Space>
                    </Card>
                  )}
                </Space>
              </Card>

              {!isFetchingDataLoading && (
                <FormAction
                  formName="Assessment"
                  onCancel={() => navigate(-1)}
                  onSubmitting={isSubmitLoading}
                  submitAccess={
                    id
                      ? ACCESS_PERMISSION.assessment.update
                      : ACCESS_PERMISSION.assessment.store
                  }
                />
              )}
            </Col>
          </Row>
        </Form>
      </VuiContainer>
    </>
  );
};
