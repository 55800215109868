import { PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";

const MultipleDateSelect = (props: any) => {
  const [open, setOpen] = useState(false);
  const value = props.value || [];
  const [render, setRender] = useState(true);

  useEffect(() => {
    setRender(false);
    setTimeout(() => setRender(true));
    if (value.length > 0) {
      props.onChange([]);
    }
  }, [props.selectedMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (date: any) => {
    let val = {
      label: moment(date).format("DD-MM-YYYY"),
      value: moment(date).format("YYYY-MM-DD"),
    };

    const selected = value.find((item: any) => item.value === val.value);

    if (selected) {
      let newValue = [...value].filter((item) => item.value !== val.value);
      props.onChange(newValue);
    } else {
      let newValue = [...value, val];
      props.onChange(newValue);
    }
  };

  const handleSelectorChange = (data: any) => {
    props.onChange(data);
  };
  return (
    <>
      <div className="multiple-dateselect">
        <div className="select-row">
          <Select
            placeholder="Pilih Tanggal"
            isSearchable={false}
            isDisabled={value.length === 0}
            isMulti
            value={value}
            menuIsOpen={false}
            className={"custom-select"}
            classNamePrefix="select"
            onChange={handleSelectorChange}
            isClearable={false}
          />
          <Button
            disabled={props.disabled || !props.selectedMonth}
            onBlur={() => setOpen(false)}
            className="add-btn"
            onClick={() => {
              setOpen(!open);
            }}
            icon={<PlusOutlined />}
            style={{ width: "50px", height: "auto" }}
          ></Button>
        </div>
        {render && (
          <div>
            <DatePicker
              defaultPickerValue={moment(props.selectedMonth).startOf("month")}
              showToday={false}
              open={open}
              disabledDate={(current) => {
                let maxDate = moment(props.selectedMonth).endOf("month");
                let minDate = moment(props.selectedMonth).startOf("month");

                let today = moment().format("YYYY-MM-DD");

                return (
                  current &&
                  (current < minDate ||
                    current > maxDate ||
                    current < moment(today, "YYYY-MM-DD"))
                );
              }}
              dateRender={(current) => {
                const style: React.CSSProperties = {};
                const selected = value?.find(
                  (item: any) => item.value === current.format("YYYY-MM-DD")
                );

                return (
                  <div
                    onClick={() => handleChange(current)}
                    className={clsx("ant-picker-cell-inner multiple", {
                      selected: selected,
                    })}
                    style={style}
                  >
                    {current.date()}
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MultipleDateSelect;
