import { useMemo } from "react";
import { Tag, TagProps } from "antd";

export type StatusTagProps = {
  title: string;
};

const StatusTag = (props: StatusTagProps) => {
  const { title } = props;

  const statusToColor: TagProps["color"] = useMemo(() => {
    switch (title) {
      case "Expired":
      case "Booked":
      case "Not Available":
        return "error";
      case "Waiting for Payment":
        return "warning";
      case "Session Starts":
      case "Ongoing":
        return "blue";
      case "Available":
      case "Booking Confirmed":
      case "Session Ends":
        return "success";
      default:
        return "error";
    }
  }, [title]);

  return <Tag color={statusToColor}>{title}</Tag>;
};

export default StatusTag;
