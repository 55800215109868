import React from "react";
import { Helmet } from "react-helmet-async";
import VuiContainer from "../../../@vodea/vodea-ui/components/Container";
import VuiSectionTitle from "../../../@vodea/vodea-ui/components/Sections/Title";
import { useAppSelector } from "../../../stores/hooks";

const breadcrumbs = [
  {
    label: "Dashboard",
    link: "/dashboard",
  },
];

const AppDashboard: React.FC<any> = () => {
  const system = useAppSelector((state) => state.system);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <VuiContainer>
        <VuiSectionTitle
          title={`Welcome, ${system?.account?.name}`}
          breadcrumbs={breadcrumbs}
        />
      </VuiContainer>
    </>
  );
};

export default AppDashboard;
