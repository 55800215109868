import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Switch from "antd/es/switch";
import Card from "antd/lib/card";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../../@vodea/vodea-ui/components/Editor";
import VuiSelectMulti from "../../../../../@vodea/vodea-ui/components/Select/Multi";
import VuiUploadImages from "../../../../../@vodea/vodea-ui/components/UploadImages";
import {
  ACCESS_PERMISSION,
  env,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../../functions/global";
import ArticleRepository from "../../../../../repositories/ArticleRepository";
import TagRepository from "../../../../../repositories/TagRepository";

const { TextArea } = Input;

let title = "Article";

const AppArticleForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      tag_ids: _.map(data.tag_ids, "id"),
      photo_id: _.get(data, "photo_id[0].id"),
      date: moment(data.date).format("YYYY-MM-DD"),
      is_active: Boolean(data.is_active),
      author_photo_id: _.get(data, "author_photo_id[0].id"),
    };

    await (!id
      ? ArticleRepository.create(formData)
      : ArticleRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const payload = {
      with: ["photo", "authorPhoto", "tags"],
    };
    await ArticleRepository.show(id, payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const modified: any = {
          ...data,
          is_active: Boolean(data.is_active),
          date: moment(data.date),
          tag_ids: data.tags,
          photo_id: toFileList(data.photo),
          author_photo_id: toFileList(data.author_photo),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title"
                        label={"Title"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description About Us",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="summary"
                        label={"Summary"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Summary",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Summary",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Image{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE
                                  .insightAndResearch.article.article,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="photo_id"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="tag_ids"
                        label={"Tags"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Tags" }),
                          },
                        ]}
                      >
                        <VuiSelectMulti
                          repository={TagRepository}
                          placeholder={t("select.placeholder", {
                            item: "Tags",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="date"
                        label={"Date"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Date" }),
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={disable.get()}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="DD-MM-YYYY"
                          style={{ width: 400, maxWidth: "100%" }}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Date",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="is_active"
                        label={"Status"}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: t("validation.required", {
                        //       item: "Status",
                        //     }),
                        //   },
                        // ]}
                        valuePropName="checked"
                      >
                        <Switch disabled={disable.get()} />
                      </Form.Item>
                    </div>
                  </div>

                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Card title={"Author"}>
                      <div className="vui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="author_name"
                            label={"Name"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Name",
                                }),
                              },
                            ]}
                          >
                            <Input
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Name",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="vui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="author_description"
                            label={"Description"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Description",
                                }),
                              },
                            ]}
                          >
                            <VuiEditor
                              disabled={disable.get()}
                              apiKey={env.getTinyApiKey()}
                              init={{
                                ...vuiEditorBaseInit,
                                placeholder: t("common.text.input", {
                                  item: "Description",
                                }),
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="vui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            label={
                              <div>
                                Image{" "}
                                <span className="optional-form-label">
                                  (
                                  {t("common.text.recommendedSize", {
                                    item: IMAGE_RECOMMENDATION_SIZE
                                      .insightAndResearch.article.author,
                                  })}
                                  )
                                </span>
                              </div>
                            }
                            name="author_photo_id"
                          >
                            <VuiUploadImages isUploadFirst />
                          </Form.Item>
                        </div>
                      </div>
                    </Card>
                  </Space>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.article.update
                  : ACCESS_PERMISSION.article.store
              }
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppArticleForm;
