import { useEffect } from "react";
import { Provider } from "react-redux";
import ConfigProvider from "antd/lib/config-provider";
import enUS from "antd/lib/locale/en_US";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { store } from "./stores";
import "./styles/style.less";

const themes = {
  dark: `/dark-theme.css`,
  light: `/light-theme.css`,
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  //<React.StrictMode>
  <Provider store={store}>
    <ThemeSwitcherProvider
      themeMap={themes}
      defaultTheme="light"
      insertionPoint="styles-insertion-point"
    >
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>Personal Growth</title>
          </Helmet>
          <ScrollToTop />
          <ConfigProvider locale={enUS}>
            <App />
          </ConfigProvider>
        </HelmetProvider>
      </BrowserRouter>
    </ThemeSwitcherProvider>
  </Provider>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
