import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICounselingFormBaseProps } from "../interface";

const CounselingFormShortAnswer: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={`${question.id}-${question.type}`}
      label={question.question}
      rules={[
        {
          required: Boolean(question.is_required),
          message: t("validation.required", {
            item: "Answer",
          }),
        },
      ]}
      tooltip={question.tooltip}
    >
      <Input
        disabled={disabled}
        size={"large"}
        placeholder={t("common.text.input", { item: "Answer" })}
      />
    </Form.Item>
  );
};

export default CounselingFormShortAnswer;
