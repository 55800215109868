import React, { useMemo } from "react";
import { IFormQuestion } from "../interface";
import CounselingFormShortAnswer from "../ShortAnswer";
import CounselingFormParagraph from "../Paragraph";
import CounselingFormMultipleChoice from "../MultipleChoice";
import CounselingFormCheckBox from "../CheckBox";
import CounselingFormDropDown from "../DropDown";
import CounselingFormRadioScale from "../RadioScale";
import CounselingFormMultipleShortAnswer from "../MultipleShortAnswer";
import CounselingFormTable from "../Table";
import { QUESTION_FORM_TYPE } from "../../../constant";
import { Card } from "antd";
import VuiFormTitle from "../../../@vodea/vodea-ui/components/Form/Title";

interface CounselingFormProps {
  formData: IFormQuestion[];
  disabled: boolean;
  forPrint?: boolean;
}

const CounselingForm: React.FC<CounselingFormProps> = ({
  formData = [],
  disabled,
  forPrint,
}) => {
  const render = useMemo(() => {
    return formData.map((question: IFormQuestion, index: number) => {
      switch (question?.type) {
        case QUESTION_FORM_TYPE.shortAnswer:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormShortAnswer
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );
        case QUESTION_FORM_TYPE.paragraph:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormParagraph
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );
        case QUESTION_FORM_TYPE.multipleChoice:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormMultipleChoice
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );
        case QUESTION_FORM_TYPE.checkBox:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormCheckBox
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );

        case QUESTION_FORM_TYPE.dropDown:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormDropDown
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );
        case QUESTION_FORM_TYPE.radioScale:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormRadioScale
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );
        case QUESTION_FORM_TYPE.multipleShortAnswer:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormMultipleShortAnswer
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );

        case QUESTION_FORM_TYPE.table:
          return (
            <Card key={index} className="mb16 form-card">
              <div key={index} className="mb24 vui-form-group type-column">
                <div className="input-section">
                  <CounselingFormTable
                    question={question}
                    disabled={disabled}
                    forPrint={forPrint}
                  />
                </div>
              </div>
            </Card>
          );

        default:
          return <div key={index} />;
      }
    });
  }, [formData, disabled]);
  return <>{render}</>;
};

export default CounselingForm;
