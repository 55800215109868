import { api, createCancelTokenHandler } from "../@vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin/report`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (
    year: number | string,
    month: number | string,
    params: any = null
  ) {
    return api.get(`${endPoint()}/api/admin/report/${year}/${month}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  export: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin/report/excel`, {
      params,
      responseType: "blob",
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
