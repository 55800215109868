import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Image from "antd/lib/image";
import Space from "antd/lib/space";
import Title from "antd/lib/typography/Title";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import CustomerRepository from "../../../../repositories/CustomerRepository";

let title = "Customer";

const AppCustomerView: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const photo = Form.useWatch("photo", form);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await CustomerRepository.show(id, { with: ["photo", "child.photo"] })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const modified: any = {
          ...data,
          date_of_birth: moment(data.date_of_birth),
          photo: data.photo?.url,
          child: data.child.map((item: any) => {
            return {
              ...item,
              date_of_birth: moment(item.date_of_birth),
              photo: item.photo?.url,
            };
          }),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"}>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item label="Photo" name="photo">
                        <Image width={140} src={photo} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Name"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Name",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Name",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="email"
                        label={"Email"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Email",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Email",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="phone_number"
                        label={"Phone Number"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Phone Number",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Phone Number",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="date_of_birth"
                        label={"Date of Birth"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Date of Birth",
                            }),
                          },
                        ]}
                      >
                        <DatePicker
                          disabled
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="DD-MM-YYYY"
                          style={{ width: 400, maxWidth: "100%" }}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Date of Birth",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="gender"
                        label={"Gender"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Gender",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Gender",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="address"
                        label={"Address"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Address",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Address",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Form.List name="child">
                    {(fields) =>
                      fields.length > 0 && (
                        <Card title="Relationship With">
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={15}
                          >
                            {fields.map(({ key, name, ...restField }) => (
                              <Card title={""} key={key}>
                                <Row align={"middle"} justify={"space-between"}>
                                  <Title level={5}>{name + 1}</Title>
                                </Row>
                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item label="Photo" name="photo">
                                      <Image width={140} src={photo} />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "name"]}
                                      label={"Name"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Name",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        disabled
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Name",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "email"]}
                                      label={"Email"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Email",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        disabled
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Email",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "phone_number"]}
                                      label={"Phone Number"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Phone Number",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        disabled
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Phone Number",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "date_of_birth"]}
                                      label={"Date of Birth"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Date of Birth",
                                          }),
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        disabled
                                        disabledDate={(current) => {
                                          let customDate =
                                            moment().format("YYYY-MM-DD");
                                          return (
                                            current &&
                                            current <
                                              moment(customDate, "YYYY-MM-DD")
                                          );
                                        }}
                                        format="DD-MM-YYYY"
                                        style={{ width: 400, maxWidth: "100%" }}
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Date of Birth",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "gender"]}
                                      label={"Gender"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Gender",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        disabled
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Gender",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "address"]}
                                      label={"Address"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Address",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        disabled
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Address",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </Space>
                        </Card>
                      )
                    }
                  </Form.List>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppCustomerView;
