import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Switch from "antd/es/switch";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import { ACCESS_PERMISSION } from "../../../../../constant";
import {
  convertLabelInValue,
  handleBackendError,
  openNotification,
} from "../../../../../functions/global";
import PsychologistRepository from "../../../../../repositories/PsychologistRepository";
import RoleRepository from "../../../../../repositories/RoleRepository";
import UserRepository from "../../../../../repositories/UserRepository";

const { Option } = Select;

let title = "User";

const AppUserForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const selectedRole = Form.useWatch("role_id", form);
  const disable = useState(false);
  const loading = useState(false);

  const isPsychologistRole = useMemo(() => {
    let isPsychologist;

    if (selectedRole?.key) {
      const splitKey = selectedRole.key.split("-");
      const isPsychologistValue = splitKey[splitKey.length - 1];

      isPsychologist = Number(isPsychologistValue);
    }
    return Boolean(isPsychologist);
  }, [selectedRole]);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      role_id: _.get(data.role_id, "value"),
      is_active: Boolean(data.is_active),
    };

    if (data.psychologist_id) {
      formData.psychologist_id = _.get(data.psychologist_id, "value");
    }

    await (!id
      ? UserRepository.create(formData)
      : UserRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const payload = {
      with: ["roles", "psychologist"],
    };
    await UserRepository.show(id, payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const roleData = _.get(data, "roles[0]");

        const role = convertLabelInValue(roleData) || null;

        const modified: any = {
          ...data,
          is_active: Boolean(data.is_active),
          role_id: role
            ? {
                ...role,
                key: `${roleData.id}-${roleData.name}-${roleData.is_psychologist}`,
              }
            : null,
          psychologist_id: convertLabelInValue(data.psychologist) || null,
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Name"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Name",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Name",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="email"
                        label={t("common.form.email.label")}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.email.label"),
                            }),
                          },
                          {
                            type: "email",
                            message: t("validation.type.email", {
                              item: t("common.form.email.label"),
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("common.form.email.placeholder")}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="phone_number"
                        label={"Phone Number"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Phone Number",
                            }),
                          },
                          {
                            pattern: new RegExp("^[6][2][0-9]*$"),
                            message:
                              "Phone Number tidak valid. Contoh: 628131234567",
                          },
                          {
                            min: 11,
                            message: t("validation.type.min", {
                              item: "Phone Number",
                              min: 11,
                            }),
                          },
                          {
                            max: 14,
                            message: t("validation.type.max", {
                              item: "Phone Number",
                              max: 14,
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Phone Number",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {!id && (
                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="password"
                          label={t("common.form.password.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.password.label"),
                              }),
                            },
                            {
                              min: 8,
                              message: t("validation.type.min", {
                                item: t("common.form.password.label"),
                                min: 8,
                              }),
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder={t("common.form.password.placeholder")}
                            size={"large"}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="role_id"
                        label={"Role"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Role" }),
                          },
                        ]}
                      >
                        <VuiSelectSingle
                          repository={RoleRepository}
                          placeholder={t("select.placeholder", {
                            item: "Role",
                          })}
                          customOptions={(item: any) => {
                            return (
                              <Option
                                key={`${item.id}-${item.name}-${item.is_psychologist}`}
                                value={item.id}
                                label={item?.name}
                              >
                                {item?.name}
                              </Option>
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {isPsychologistRole && (
                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="psychologist_id"
                          label={"Psychologist"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Psychologist",
                              }),
                            },
                          ]}
                        >
                          <VuiSelectSingle
                            repository={PsychologistRepository}
                            placeholder={t("select.placeholder", {
                              item: "Psychologist",
                            })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="is_active"
                        label={"Status"}
                        valuePropName="checked"
                      >
                        <Switch disabled={disable.get()} />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.user.update
                  : ACCESS_PERMISSION.user.store
              }
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppUserForm;
