import moment from "moment";

export const timeFormat = (value: string): string => {
  const dateTime = moment(value, "HH:mm:ss");
  return dateTime.format("HH:mm");
};

export const dateTimeViewFormat = (
  date: string,
  timeFrom: string,
  timeTo: string
): string => {
  const startTime = timeFormat(timeFrom);
  const endTime = timeFormat(timeTo);
  return `${moment(date).format("DD MMM YYYY")} | ${startTime} - ${endTime}`;
};
