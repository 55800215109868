import { useState } from "@hookstate/core";
import { Checkbox, Spin, Table } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import { ColumnType } from "antd/lib/table";
import { AxiosError, AxiosResponse } from "axios";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import { ACCESS_PERMISSION } from "../../../../../constant";
import { openNotification } from "../../../../../functions/global";
import PermissionRepository from "../../../../../repositories/PermissionRepository";
import RoleRepository from "../../../../../repositories/RoleRepository";

interface ITableData {}

const baseColumns: ColumnType<ITableData> = {
  title: "",
  dataIndex: "name",
  key: "name",
  className: "text-capitalize",
};

let title = "Role";

const AppRoleForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [permissionColumns, setPermissionColumns] = React.useState<
    ColumnType<ITableData>[]
  >([]);
  const [permissionTableData, setPermissionTableData] = React.useState<
    ITableData[]
  >([]);

  // Form
  const [form] = Form.useForm();
  const isPsychologist = Form.useWatch("is_psychologist", form);
  const selectedPermissions = useState<number[]>([]);
  const disable = useState(false);
  const isSubmitting = useState(false);

  const onFinish = useCallback((values: any) => {
    setIsLoading(true);

    const payload = {
      ...values,
    };

    if (!values.is_psychologist) {
      payload.permission_ids = selectedPermissions.value;
    }

    (id ? RoleRepository.update(id, payload) : RoleRepository.create(payload))
      .then(() => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
        openNotification("error", t("notification.error.default"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsLoading(true);

    let repository: any = () => Promise.all([PermissionRepository.all()]);

    if (id) {
      repository = () =>
        Promise.all([
          PermissionRepository.all(),
          RoleRepository.show(id, { with: "gateSetting.permissions" }),
        ]);
    }

    repository()
      .then((res: AxiosResponse[]) => {
        const permissions = res[0].data?.data || [];

        if (id) {
          const roles = res[1].data?.data || {};
          const currentPermissions = roles?.gate_setting?.permissions?.map(
            (item: any) => item.id
          );
          selectedPermissions.set(currentPermissions);
          form.setFieldsValue({
            name: roles.name,
            is_psychologist: Boolean(roles.is_psychologist),
          });
        }

        toPermissionTable(permissions);
      })
      .catch((e: AxiosError) => {
        console.log(e, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const transformPermission = (permissions: any[]) => {
    let baseData: any = {};
    const tableColumns: ColumnType<ITableData>[] = [baseColumns];

    permissions.forEach((permission) => {
      const name = permission.name.split(".")[1];
      if (baseData[name]) {
        baseData[name][permission.method] = permission.id;
      } else {
        baseData = {
          ...baseData,
          [name]: {
            [permission.method]: permission.id,
          },
        };
      }

      const isExist = tableColumns.find(
        (item) => item.key === permission.method
      );

      if (!isExist) {
        tableColumns.push({
          ...baseColumns,
          title: permission.name.split(".")[2].replaceAll("-", " "),
          dataIndex: permission.method,
          key: permission.method,
        });
      }
    });

    return {
      baseData,
      tableColumns,
    };
  };

  const toPermissionTable = (permissions: any[]) => {
    const { baseData, tableColumns } = transformPermission(permissions);

    const tableData = Object.keys(baseData).map((key) => {
      return {
        name: key.replaceAll("-", " "),
        ...baseData[key],
      };
    });

    setPermissionTableData(tableData);
    setPermissionColumns(tableColumns);
  };

  const handleChange = (id: number) => {
    let arr: number[] = selectedPermissions.get() || [];
    if (arr.includes(id)) {
      arr = arr.filter((item: any) => item !== id);
    } else {
      arr = [...arr, id];
    }
    selectedPermissions.set(arr);
  };

  const handleChecked = useCallback(
    (id: number) => {
      const arr: number[] = selectedPermissions?.get();
      if (arr?.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    [selectedPermissions.get()]
  );

  const getPermissionColumns: ColumnType<ITableData>[] = [
    ...permissionColumns,
  ].map((item: ColumnType<ITableData>, i) => {
    if (i !== 0) {
      item.render = (text: string, record: any) =>
        record[item.key as string] ? (
          <Checkbox
            checked={handleChecked(record[item.key as string])}
            onChange={() => handleChange(record[item.key as string])}
          />
        ) : (
          <span />
        );
    }
    return item;
  });

  return (
    <>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Card>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
              size={4}
            >
              <div className="vui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="name"
                    label={"Peran"}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", { item: "Peran" }),
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.get()}
                      size={"large"}
                      placeholder={t("common.text.input", { item: "Peran" })}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="vui-form-group type-column">
                <div className="input-section">
                  <Form.Item name="is_psychologist" valuePropName="checked">
                    <Checkbox disabled={disable.get()}>
                      As Psychologist
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            </Space>
          </Card>

          <br />

          {!isPsychologist && (
            <Card style={{ overflow: "auto" }}>
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={10}
              >
                <div>Izin Peran</div>
                <Table
                  rowKey={"name"}
                  loading={isLoading}
                  dataSource={permissionTableData}
                  columns={getPermissionColumns}
                  pagination={false}
                />
              </Space>
            </Card>
          )}

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>

            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.role.update
                  : ACCESS_PERMISSION.role.store
              }
            >
              <Spin spinning={isSubmitting.get()}>
                <Button
                  className="vui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppRoleForm;
