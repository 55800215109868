import "./style.less";

import { FormItemViewProps } from "./interface";

import { useMemo } from "react";
import { toFileList } from "../../../../../functions/global";
import { Space, Typography } from "antd";

import _ from "lodash";
import VuiUpload from "../../Upload";

const { Text } = Typography;

const FormItemView = ({
  label,
  type = "text",
  value,
  customvalue,
  spaceSize = 6,
}: FormItemViewProps) => {
  const renderValue = useMemo(() => {
    return type === "file" ? (
      !_.isEmpty(value) ? (
        <VuiUpload disabled value={toFileList(value)} />
      ) : (
        "-"
      )
    ) : (
      <Text className="form-item-view-value">{value || "-"}</Text>
    );
  }, [type, value]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={spaceSize}>
      <Text className="form-item-view-label" type="secondary">
        {label}
      </Text>
      {customvalue ? customvalue : renderValue}
    </Space>
  );
};

export default FormItemView;
